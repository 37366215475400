<template>
    <div class="view-apply-page">
        <div>
            <span class="font-20">{{ applyForm.name }}</span>
            申请此线索
        </div>
        <div>
            本月第
            <span class="font-20">{{ applyForm.count }}</span>
            次申请线索
        </div>
        <div class="footer-wrap">
            <el-button size="small" type="primary" @click="handleTApplyBtn(1)">通过申请</el-button>
            <el-button size="small" type="info" @click="handleTApplyBtn(2)">拒绝申请</el-button>
        </div>
    </div>
</template>

<script>
import { viewApplyAction, viewApplyAuditAction } from '@/common/api/customerCenter/brandCustomerCenter.js';
export default {
    name: 'viewApplyDialog',
    props: {
        viewApplyData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            applyForm: {
                name: '',
                count: ''
            }
        };
    },
    created() {
        this.queryViewApplyData();
    },
    methods: {
        // 查询查看申请数据
        queryViewApplyData() {
            let params = {
                BrandID: this.viewApplyData.brandId
            };
            viewApplyAction(params)
                .then(res => {
                    let { code, BrandName, Count } = res;
                    if (code === 200) {
                        this.applyForm = {
                            name: BrandName,
                            count: Count
                        };
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理通过/拒绝申请按钮
        handleTApplyBtn(type) {
            let status = type === 1 ? 6 : 7;
            let params = {
                ID: this.viewApplyData.id,
                Status: status,
                RejectReason: ''
            };
            viewApplyAuditAction(params)
                .then(res => {
                    let { code, msg } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.$emit('closeDialog', false);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.view-apply-page {
    div {
        text-align: center;
    }

    div:first-child {
        margin-bottom: 10px;
    }

    .font-20 {
        font-size: 20px;
    }

    .footer-wrap {
        margin-top: 20px;
    }
}
</style>