import $http from '@/utils/http.js';

/**
 * 入驻意向品牌信息列表
 * @param data
 */
export function getBrandCustomerCenterList(data) {
    return $http.get('/brandcenter/index', data, 'loadingDiv');
}

/**
 * 导出入驻意向品牌信息列表操作
 * @param data
 */
export function downloadBrandCustomerCenterListAction(data) {
    return $http.exports('/brandcenter/export', data, 'loadingDiv', '入驻意向品牌信息列表.xlsx');
}

/**
 * 更改入驻意向品牌信息在线状态操作
 * @param data
 */
export function changeBrandCustomerCenterStatusAction(data) {
    return $http.post('/brandcenter/change', data, 'loadingDiv');
}

/**
 * 查看入驻意向品牌信息查看申请操作
 * @param data
 */
export function viewApplyAction(data) {
    return $http.post('/brandcenter/getclue', data, 'loadingDiv');
}

/**
 * 入驻意向品牌信息查看申请审核操作
 * @param data
 */
export function viewApplyAuditAction(data) {
    return $http.post('/brandcenter/examine', data, 'loadingDiv');
}

/**
 * 入驻意向品牌信息列表删除操作
 * @param data
 */
export function brandCustomerCenterDeleteAction(data) {
    return $http.post('/brandcenter/delete', data, 'loadingDiv');
}
