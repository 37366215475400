<template>
    <div class="brand-customer-center-page">
        <div class="brand-customer-center-header">
            <div class="header-left">
                <el-select size="small" v-model="brandCustomerForm.status" clearable placeholder="请选择状态">
                    <el-option v-for="item in brandCustomerStatusList" :key="item.id" :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-input size="small" v-model="brandCustomerForm.searchVal" placeholder="请输入企业名称" clearable></el-input>
                <el-button size="small" type="primary" @click="handleSearch">查询</el-button>
            </div>
            <div class="header-right">
                <!-- <el-button size="small" type="primary" @click="handleExport">导出</el-button>
                <span style="margin: 0 20px; top: 2px">|</span> -->
                <span style="top: 3px; margin-right: 20px">共{{ page.count }}条</span>
            </div>
        </div>
        <div class="brand-customer-center-table">
            <el-table v-loading="loading" :header-cell-style="{ background: '#F2F2F2' }" :data="brandCustomerDataList"
                border align="center" style="width: 100%">
                <el-table-column label="序号" width="60" type="index" :index="indexMethod" align="center">
                </el-table-column>
                <el-table-column prop="enterpriseName" label="企业名称" align="center"></el-table-column>
                <el-table-column prop="expectBrand" label="期望合作品牌" align="center"></el-table-column>
                <el-table-column prop="belongsRegion" label="所属地区" align="center"></el-table-column>
                <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                <el-table-column prop="bindPhone" label="绑定手机号" align="center">
                    <template v-slot="scope">
                        <div>{{ scope.row.bindPhone ? scope.row.bindPhone : '/' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="表单填写时间" align="center" width="160"></el-table-column>
                <el-table-column prop="status" label="线索状态" align="center">
                    <template v-slot="scope">
                        <div :class="[
                            scope.row.status === 2
                                ? 'gray-color'
                                : scope.row.status === 7
                                ? 'red-color'
                                : scope.row.status === 3 || scope.row.status === 4 || scope.row.status === 5
                                ? 'blue-color'
                                : ''
                        ]">
                            {{ cluesStatusName(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="180">
                    <template v-slot="scope">
                        <el-button v-if="scope.row.status === 4" @click="handleCheckCluesBtn(scope.row)" type="text"
                            size="small">查看申请
                        </el-button>
                        <!-- <el-button @click="handleEditNoteBtn(scope.row)" type="text" size="small">编辑备注</el-button> -->
                        <el-button @click="handleChangeCluesStatus(scope.row)" type="text" size="small">线索状态</el-button>
                        <el-button @click="handleDeleteBtn(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align: right; margin-top: 30px" v-if="brandCustomerDataList.length > 0">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="page.page" :page-sizes="[10, 30, 40, 50]" :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="page.count">
            </el-pagination>
        </div>
        <!-- 查看申请弹窗 -->
        <dialogFrame width="450px" :show="dialogViewApplyVisible" @showDialog="showDialog" :title="'线索申请'"
            :footer="false">
            <viewApplyDialog v-if="dialogViewApplyVisible" :viewApplyData="viewApplyData" @closeDialog="closeDialog">
            </viewApplyDialog>
        </dialogFrame>
        <!-- 线索状态弹窗 -->
        <dialogFrame width="460px" :show="dialogCluesStatusVisible" @showDialog="showDialog" :title="'线索状态'"
            :footer="false">
            <cluesStatusDialog v-if="dialogCluesStatusVisible" :cluesStatusData="cluesStatusData"
                :brandCustomerStatusList="brandCustomerStatusList" @closeDialog="closeDialog"></cluesStatusDialog>
        </dialogFrame>
    </div>
</template>

<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import viewApplyDialog from '@/views/customerCenter/brandCustomerCenter/components/viewApplyDialog.vue';
import cluesStatusDialog from '@/views/customerCenter/brandCustomerCenter/components/cluesStatusDialog.vue';
import {
    getBrandCustomerCenterList,
    downloadBrandCustomerCenterListAction,
    brandCustomerCenterDeleteAction
} from '@/common/api/customerCenter/brandCustomerCenter.js';
export default {
    components: {
        dialogFrame,
        viewApplyDialog,
        cluesStatusDialog
    },
    data() {
        return {
            brandCustomerForm: {
                // 状态
                status: '',
                searchVal: ''
            },
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            // 状态列表
            brandCustomerStatusList: [],
            // 表格数据
            brandCustomerDataList: [],
            loading: false,
            // 查看申请弹窗显示
            dialogViewApplyVisible: false,
            viewApplyData: {},
            // 线索状态弹窗显示
            dialogCluesStatusVisible: false,
            cluesStatusData: {}
        };
    },
    created() {
        this.queryBrandCustomerCenterData();
    },
    methods: {
        showDialog(val) {
            this.dialogViewApplyVisible = val;
            this.dialogCluesStatusVisible = val;
        },
        closeDialog(val) {
            this.dialogViewApplyVisible = val;
            this.dialogCluesStatusVisible = val;
            this.queryBrandCustomerCenterData();
        },
        async queryBrandCustomerCenterData() {
            this.loading = true;
            let params = {
                Status: this.brandCustomerForm.status,
                EnterpriseName: this.brandCustomerForm.searchVal,
                Page: this.page.page,
                Len: this.page.pageSize
            };
            let res = await getBrandCustomerCenterList(params);
            let { code, Status, BrandInfo, count } = res;
            if (code === 200) {
                this.brandCustomerStatusList = Status;
                this.brandCustomerStatusList.unshift({
                    id: 0,
                    name: '全部'
                });
                this.brandCustomerDataList = BrandInfo.map(item => {
                    return {
                        id: item.ID,
                        brandId: item.BrandID,
                        enterpriseName: item.EnterpriseName,
                        expectBrand: item.BrandName,
                        belongsRegion: item.Areas,
                        name: item.RealName,
                        phone: item.MobilePhone,
                        bindPhone: item.BindingMobile,
                        time: item.CreateTime,
                        status: item.Status.Status
                    };
                });
                this.page.count = count;
                this.loading = false;
            }
        },
        cluesStatusName(row) {
            return this.brandCustomerStatusList.find(val => val.id === row.status)?.name;
        },
        // 处理查询按钮
        handleSearch() {
            this.queryBrandCustomerCenterData();
        },
        // 处理导出按钮
        handleExport() {
            downloadBrandCustomerCenterListAction()
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '导出成功',
                        center: true
                    });
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理查看线索按钮
        handleCheckCluesBtn(row) {
            let { id, brandId } = row;
            this.viewApplyData = {
                id,
                brandId
            };
            this.dialogViewApplyVisible = true;
        },
        // 处理线索状态按钮
        handleChangeCluesStatus(row) {
            let { id, status } = row;
            this.cluesStatusData = {
                id,
                status
            };
            this.dialogCluesStatusVisible = true;
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            let params = {
                ID: row.id
            };
            this.$confirm('是否进行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    brandCustomerCenterDeleteAction(params)
                        .then(res => {
                            let { code } = res;
                            if (code === 200) {
                                this.queryBrandCustomerCenterData();
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                    center: true
                                });
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryBrandCustomerCenterData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.queryBrandCustomerCenterData();
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog) {
    border-radius: 5px;
}

.brand-customer-center-page {
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;

    .brand-customer-center-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-left {

            .el-select,
            .el-input {
                margin-right: 20px;
            }

            .el-input {
                width: 200px;
            }
        }

        .header-right {
            span {
                position: relative;
                font-size: 18px;
            }
        }
    }

    .brand-customer-center-table {
        margin-top: 30px;

        .red-color {
            color: red;
        }

        .blue-color {
            color: #409eff;
        }

        .gray-color {
            color: #b2b2b2;
        }
    }
}
</style>
